import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Paatalu.css';
import Loader from '../Loader/Loader'; // Adjust the path as per your file structure

const Paatalu = () => {
   

    const [loading, setLoading] = useState(false);
    const [paatalu, setpaatalu] = useState([]);


    const fetchPaatalu = async () => {
        setLoading(true);
        try {
          const response = await axios.get('https://nabonda.infinityfreeapp.com/server/all-paatalu.php');
          //console.log(response.data)
          setpaatalu(response.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching surprises:', error);
          setLoading(false);
        }
        finally{
          setLoading(false);
        }
      };


    useEffect(()=>{
        fetchPaatalu();
      },[]);

  return (
    <div className="paatalu-welcome-container">
    {loading && (
      <div className="loader-overlay">
        <Loader />
      </div>
    )}
  <div className='paatalu-welcome-message'> 
  <table>
            <thead>

              <tr>
              <th className='paatalu-th'>Paata peru</th>
            <th className='paatalu-th'>Paata vinandi</th>
              </tr>
            </thead>
            <tbody>
              {paatalu.map((paata) => (
                <tr key={paata.id}>
                  <td className='paatalu-td'>{paata.name}</td>
                  <td>
                  <img
                    src="/assets/images/paatalu.png"
                    alt="paata"
                    onClick={() => window.open(paata.url, '_blank', 'noopener,noreferrer')}
                    style={{ cursor: 'pointer', width: '30px', height: '30px' }}
                />
                  </td>

                </tr>
              ))}
            </tbody>
          </table>

  </div>
  </div>
  );
};

export default Paatalu;
