import React, { useState } from 'react';
import './Start.css';

const Start = ({ setAuthenticated }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const correctPassword = '31012001'; // Replace with the correct password

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      setAuthenticated(true);
      setError('');
    } else {
      setError('Incorrect password');
    }
  };

  return (
    <div className="start-container">
      <h4>Please enter the password</h4>
      <form onSubmit={handlePasswordSubmit}>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="password-input"
          placeholder="Enter password"
        />
        <br/>
        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default Start;
