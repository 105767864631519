// App.js

import React, { useState } from 'react';
import './App.css';
import Start from './components/Start/Start';
import Welcome from './components/Welcome/Welcome';
import Admin from './components/Admin/Admin';
import {   HashRouter as Router, Routes, Route } from 'react-router-dom';
import Misc from './components/Misc/Misc';
import Paatalu from './components/Paatalu/Paatalu';
import Shubodayam from './components/Shubodayam/Shubodayam';

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Routes>
            <Route path="/" element={!authenticated ? <Start setAuthenticated={setAuthenticated} /> : <Welcome/>} />
            <Route path="/admin" element={ <Admin /> } />
            <Route path="/misc" element={!authenticated ? <Start setAuthenticated={setAuthenticated} /> : <Misc/>}/>
            <Route path="/paatalu" element={!authenticated ? <Start setAuthenticated={setAuthenticated} /> : <Paatalu/>}/>
            <Route path="/shubodayam" element={!authenticated ? <Start setAuthenticated={setAuthenticated} /> : <Shubodayam/>}/>

          </Routes>
        </header>
      </div>
    </Router>
  );
}

export default App;
