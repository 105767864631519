import React, { useState, useEffect } from 'react';
import './Welcome.css';
import axios from 'axios';
import Loader from '../Loader/Loader'; // Adjust the path as per your file structure

const Welcome = () => {
  const [loading, setLoading] = useState(false);
  const [nosurpriseDialog, setnosurpriseDialog] = useState(false);
  const [surprisesaipoinai, setsurprisesaipoinai] = useState(false);
  const [surpriseDialog, setsurpriseDialog] = useState(false);
  const [greeting, setGreeting] = useState('');
  const[surpriseTitle, setSurpriseTitle] = useState(null);
  const[surpriseContenttype, setSurpriseContenttype] = useState(null);
  const [surpriseContent, setSurpriseContent] = useState(null);
  const[surprises, setSurprises] = useState([]);
  
  
  /* const targetDate = new Date('2018-09-07T11:11:00');
  const now = new Date();
  const diff = now - targetDate;

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30.44); // Average days in a month
  const years = Math.floor(days / 365.25); // Average days in a year
 */

  useEffect(() => {
    const getGreetingMessage = () => {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();
      const currentMinutes = currentTime.getMinutes();
      const formattedMinutes = currentMinutes < 10 ? '0' + currentMinutes : currentMinutes;
      const period = currentHour >= 12 ? 'pm' : 'am';
      const formattedHour = currentHour % 12 === 0 ? 12 : currentHour % 12;
      const time = `${formattedHour}:${formattedMinutes}${period}`;

      if (currentHour >= 4 && currentHour < 7) {
        return 'Welcome Buddi Garu, Entandi intha jaldi lecharu';
      } else if (currentHour >= 7 && currentHour < 12) {
        return 'Welcome Buddi Garu, Good morning';
      } else if (currentHour >= 12 && currentHour < 16) {
        return 'Welcome Buddi Garu, Good afternoon';
      } else if (currentHour >= 16 && currentHour < 23) {
        return 'Welcome Buddi Garu, Good evening';
      } else if (currentHour >= 23 || currentHour < 1) {
        return `Welcome Buddi Garu, time ${time} avtundi Good night cheppi inka padukondi`;
      } else if (currentHour >= 1 && currentHour < 4) {
        return `Welcome Buddi Garu, time ${time} avtundi andi, website ekkadiki podhu padukondi inka`;
      }
    };
    let n=1;
    if(n===1){
     // console.log('calling')
      fetchSurprises();
      n=0;
    }
    setGreeting(getGreetingMessage());
  }, []);

 

  const fetchSurprises = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://nabonda.infinityfreeapp.com/server/all-surprises.php');
      
      

      

      //console.log(response.data);
      setSurprises(response.data/* .slice(1, -1).split('},{').map(item => {
        return '{' + item.replace(/^\{/, '').replace(/\}$/, '') + '}';
      }) */);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching surprises:', error);
    }
    finally{
    }
  };


  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        setnosurpriseDialog(false);
        setsurprisesaipoinai(false);
        setsurpriseDialog(false);
       // setshowFirstTimeDialog(false);
      }
    };

    document.addEventListener('keydown', handleEsc);

    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, []);


  const handleClick = async (page) => {
    setLoading(true); // Start loading
    const number = Math.floor(Math.random() * 10) + 1;
    if (number  === 5) {
        setnosurpriseDialog(true);
        setsurprisesaipoinai(false);
        setLoading(false);
    } else {
      //console.log(surprises);
      try {

        if (surprises.length === 0) {
          setsurprisesaipoinai(true);
          return;
        }
    
        else{

        

        const randomIndex = Math.floor(Math.random() * surprises.length);
        const randomSurprise = surprises[randomIndex];

        const content = randomSurprise/* .slice(1, -1).split('},{').map(item => {
          return '{' + item.replace(/^\{/, '').replace(/\}$/, '') + '}';
        }) 
          
          const content = JSON.parse(content1[0]);

          console.log('content');
        console.log(content);
        console.log(surprises);
        console.log(typeof surprises);  */
        
        const updatedSurprises = surprises.filter((_, index) => index !== randomIndex);

        setSurprises(updatedSurprises);

          let formattedContent = null;

          
          switch (content.type) {
            case 'TEXT':
              formattedContent = { type: 'text', value: content.data };
              break;
            case 'IMG':
              formattedContent = { type: 'image', value: `${content.data}` };
              break;
            case 'AUD':
              formattedContent = { type: 'audio', value: `${content.data}` };
              break;
            case 'VID':
              formattedContent = { type: 'video', value: `${content.data}` };
              break;
            default:
              formattedContent = null;
          }
          
          //console.log(formattedContent);
  
          if (formattedContent) {
            setSurpriseTitle(content.title);
            setSurpriseContenttype(content.type);
            setSurpriseContent(formattedContent);
            setsurpriseDialog(true);
          
          }
          setLoading(false); // Stop loading
        }

      } catch (error) {
        console.error('Error fetching surprise:', error);
        setLoading(false); // Stop loading

      }finally {
        setLoading(false); // Stop loading
      }
    }
  };

 /*  const [showFirstTimeDialog, setshowFirstTimeDialog] = useState(false);

  const handleFirsttimeDialog = () => {
    setshowFirstTimeDialog(true);
  };

  const handleCloseFirsttimeDialog = () => {
    setshowFirstTimeDialog(false);
  }; */

  

  const closeDialog = () => {
    setnosurpriseDialog(false);
  };


  const closesurpriseDialog = () => {
    setsurpriseDialog(false);
  };

  const closesaDialog = () => {
    setsurprisesaipoinai(false);
  };

  return (
    <div className="welcome-container">
      {loading && (
        <div className="loader-overlay">
          <Loader />
        </div>
      )}
      <div className="welcome-message">
        {greeting}
      </div>
      {/* <div className='message-container'>
  <div className='firsttime-message'> 
    Mimmalni first time kalisi...
  </div>
  <button className='firsttime-button' onClick={handleFirsttimeDialog}>click me</button>
  
</div> */}


      <div className="button-grid">
        <div className="button-row">
          <button className="round-button" onClick={() => handleClick('Page1')}>Surprise 1</button>
          <button className="round-button" onClick={() => handleClick('Page2')}>Surprise 2</button>
          <button className="round-button" onClick={() => handleClick('Page3')}>Surprise 3</button>
        </div>
        <div className="button-row">
          <button className="round-button" onClick={() => handleClick('Page4')}>Surprise 4</button>
          <button className="round-button" onClick={() => handleClick('Page5')}>Surprise 5</button>
          <button className="round-button" onClick={() => handleClick('Page6')}>Surprise 6</button>
        </div>
        <div className="button-row">
          <button className="round-button" onClick={() => handleClick('Page7')}>Surprise 7</button>
          <button className="round-button" onClick={() => handleClick('Page8')}>Surprise 8</button>
          <button className="round-button" onClick={() => handleClick('Page9')}>Surprise 9</button>
        </div>
        <div className="button-row">
          <button className="round-button" onClick={() => handleClick('Page10')}>Surprise 10</button>
          <button className="round-button" onClick={() => handleClick('Page11')}>Surprise 11</button>
          <button className="round-button" onClick={() => handleClick('Page12')}>Surprise 12</button>
        </div>
        <div className="button-row">
          <button className="round-button" onClick={() => handleClick('Page13')}>Surprise 13</button>
          <button className="round-button" onClick={() => handleClick('Page14')}>Surprise 14</button>
          <button className="round-button" onClick={() => handleClick('Page15')}>Surprise 15</button>
        </div>
      </div>

      <div className='misc-style'>

<a href="#misc" style={{fontSize: '25px', fontWeight:'bold', color:'white'}}>Miscellaneous</a>
</div>
      
      {nosurpriseDialog && (
        <div className="dialog-overlay">
          <div className="dialog-box">
            <div className="dialog-content">
              <h2>Uh-oh!</h2>
              <p>There is no surprise.. please try again</p>
              <button onClick={closeDialog} className="close-button">Close</button>
            </div>
          </div>
        </div>
      )}

{surprisesaipoinai && (
        <div className="dialog-overlay">
          <div className="dialog-box">
            <div className="dialog-content">
              <h2>Bagunnaya surprises?</h2>
              <p>Chalo surprises anni aipoinai padkondi inka</p>
              <p>Malli choosthara?? Refresh cheyyandi</p>
              <button onClick={closesaDialog} className="close-button">Close</button>
            </div>
          </div>
        </div>
      )}

  {surpriseDialog && (
        <div className="dialog-overlay">
          <div className="dialog-box">
            <div className="dialog-content">
              <h2>{surpriseTitle}</h2>
              {surpriseContenttype === 'TEXT' && <p>{surpriseContent.value}</p>}
              {surpriseContenttype === 'IMG' &&  <img src={surpriseContent.value} alt="Surprise" style={{ maxWidth: '50%', maxHeight: '50%', objectFit: 'contain' }} />}
              {surpriseContenttype === 'AUD' && <audio controls src={surpriseContent.value} />}
              {surpriseContenttype === 'VID' && <video controls src={surpriseContent.value} style={{ maxWidth: '50%', maxHeight: '50%', objectFit: 'contain' }}/>}<br></br>
              <button onClick={closesurpriseDialog} className="close-button">Close</button>
            </div>
          </div>
        </div>
      )}

{/* {
  showFirstTimeDialog && (
    <div className="dialog-overlay">
    <div className="dialog-box">
    <div className="dialog-content">
      <p>Years: {years}</p>
      <p>Months: {months}</p>
      <p>Weeks: {weeks}</p>
      <p>Days: {days}</p>
      <p>Hours: {hours}</p>
      <p>Minutes: {minutes}</p>
      <p>Seconds: {seconds}</p>
      <button onClick={handleCloseFirsttimeDialog} className="close-button">Close</button>

    </div>
    </div>
  </div>
  )
}

 */}
     
    </div>
  );
};

export default Welcome;
