import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Misc.css';
import Loader from '../Loader/Loader'; // Adjust the path as per your file structure
import CryptoJS from 'crypto-js';

const Misc = () => {

    const [loading, setLoading] = useState(false);


    const [savekeyDialog, setsavekeyDialog] = useState(false);

    const[displayKey, setdisplayKey] = useState('');

    const [secretText, setsecretText] = useState('');

  

    const targetDate = new Date('2018-09-07T11:11:00');
    const now = new Date();
    const diff = now - targetDate;
  
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30.44); // Average days in a month
    const years = Math.floor(days / 365.25); // Average days in a year
    const remainingMonths = Math.floor((days % 365.25) / 30.44);
    const remainingDays = Math.floor((days % 365.25) % 30.44);



    const handleMusicButton=()=>{
      window.location.hash = 'paatalu';
    }

    const handleMorningButton=()=>{
      window.location.hash = 'shubodayam';
    }


    const handleAddSubmit = async (event) => {
      event.preventDefault();
      // Prepare form data
      setLoading(true);
     
      const randomKey = CryptoJS.lib.WordArray.random(16).toString(); // 16 bytes = 128 bits
      
      // Encrypt the secret text using the random key
      const encryptedSecret = CryptoJS.AES.encrypt(secretText, randomKey).toString();

      const formData = new FormData();
      formData.append('secret', encryptedSecret);
      formData.append('key', randomKey);
          try {
              // Send POST request to upload data
              const response = await axios.post('https://nabonda.infinityfreeapp.com/server/add-secret-text.php', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              });
              setLoading(false);
              setsecretText('');
              alert(response.data.message);
              setdisplayKey(randomKey);
              setsavekeyDialog(true);
              // Optionally, fetch updated surprises data
              //fetchSurprises();
            } catch (error) {
              setLoading(false);
  
              console.error('Error adding surprise:', error);
            }finally{
              setLoading(false);

            }
            
      
  
      
    };


    const closeDialog = () => {
      setsavekeyDialog(false);
    };


    useEffect(() => {
      const handleEsc = (event) => {
        if (event.key === 'Escape') {
          setsavekeyDialog(false);
         // setshowFirstTimeDialog(false);
        }
      };
  
      document.addEventListener('keydown', handleEsc);
  
      return () => {
        document.removeEventListener('keydown', handleEsc);
      };
    }, []);
  


  return (
    <div className="misc-welcome-container">
    {loading && (
      <div className="loader-overlay">
        <Loader />
      </div>
    )}
  <div className='misc-welcome-message'> 
    Oka angel naa life loki ochi...
  </div>
  {/* <button className='firsttime-button' onClick={handleFirsttimeDialog}>click me</button>
   */}
      
<div className='time-message'>
<p>    {years} years {remainingMonths} months {remainingDays} days <br></br> {months} months | {weeks} weeks | {days} days <br></br> {hours} hours | {minutes} minutes | {seconds} seconds </p>

</div>
<div style={{fontSize:'20px',fontWeight:'bold', transform: 'translateY(-80%)'}}>avtundi
  </div>

  <br></br>
  <div className='misc-welcome-message'>
    Naakosam meeru pampina paatalu...
  </div>
    <br></br>
  <button className='round-button' onClick={handleMusicButton}>
    vinalani undha?
  </button>
 

  <div className='misc-welcome-message'>
    Meekosam nenu pampina morning messages...
  </div>
    <br></br>
  <button className='round-button' onClick={handleMorningButton}>
    choodalani undha?
  </button>

<div className='misc-add-message'>
    Naaku edaina cheppali ante chepochu.. <br></br>
    It'll be encrypted no one can read it, even me..<br></br>
    oka random key ostadi adhi save cheyyandi.. <br></br>
    one fine day cheppandi  
  </div>

  
  <div className="misc-admin-form">
          
          <form onSubmit={handleAddSubmit}>
           
            <input type="text" className='misc-input' placeholder="eg : em cheppali na bonda" value={secretText} onChange={(e) => setsecretText(e.target.value)} />
            <br></br>
            <button type="submit" className='misc-submit-button'>Submit</button>
          </form>
        </div>

        {savekeyDialog && (
        <div className="dialog-overlay">
          <div className="dialog-box">
            <div className="dialog-content">
              <h2>Please copy this key or take a screenshot</h2>
              <p>{displayKey}</p>
              <button onClick={closeDialog} className="close-button">Close</button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default Misc;
