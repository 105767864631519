import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Shubodayam.css';
import Loader from '../Loader/Loader'; // Adjust the path as per your file structure

const Shubodayam = () => {
   

    const [loading, setLoading] = useState(false);
    const [shubodayam, setshubodayam] = useState([]);


    const fetchshubodayam = async () => {
        setLoading(true);
        try {
          const response = await axios.get('https://nabonda.infinityfreeapp.com/server/all-shubodayam.php');
          //console.log(response.data)
          setshubodayam(response.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching surprises:', error);
          setLoading(false);
        }
        finally{
          setLoading(false);
        }
      };


    useEffect(()=>{
        fetchshubodayam();
      },[]); 

  return (
    <div className="shubodayam-welcome-container">
    {loading && (
      <div className="loader-overlay">
        <Loader />
      </div>
    )}
  <div className='shubodayam-welcome-message'> 
  <table>
           
            <tbody>
                <br>
                </br>
              {shubodayam.map((shubo) => (
                <tr key={shubo.id}>
                  <td className='shubodayam-td'>{shubo.data}</td>
                </tr>
              ))}
            </tbody>
          </table>

  </div>
  </div>
  );
};

export default Shubodayam;
