import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Admin.css';
import Loader from '../Loader/Loader'; // Adjust the path as per your file structure

const Admin = () => {
    const [loading, setLoading] = useState(false);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const [action, setAction] = useState('');
  const [type, setType] = useState('TEXT');
  const [title, setTitle] = useState('');
  const [shubodayam, setShubodayam] = useState('');
  const [paata, setpaata] = useState('');
  const [paataurl, setpaataurl] = useState('');

  const [dataText, setdataText] = useState('');
  const [file, setFile] = useState(null);
  const [surprises, setSurprises] = useState([]);
  const [error, setError] = useState('');

  const[displaysurprises,setdisplaysurprises] = useState(true);



  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  
  
  // Function to handle authentication
  const handleAuthentication = (e) => {
    e.preventDefault();

    // Check username and password (implement your authentication logic here)
    if (username === 'buddigaru' && password === 'bangaram') {
      setAuthenticated(true);
      setError('');

    } else {
        setError('Invalid credentials');
    }
  };

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

  // Function to handle form submission for Add operation
  const handleAddSubmit = async (event) => {
    event.preventDefault();
    // Prepare form data
    setLoading(true);
    if (type !== 'TEXT') {
      const formData = new FormData();
      formData.append('type', type);
      formData.append('title', title);

          formData.append('file', await toBase64(file));
      
        //console.log(formData);
          try {
              // Send POST request to upload data
              const response = await axios.post('https://nabonda.infinityfreeapp.com/server/add-surprise.php', formData, {
                  headers: {
                      'Content-Type': 'multipart/form-data'
                  }
              });
              setLoading(false);
              //console.log(response);
              alert(response.data.message);
              // Optionally, fetch updated surprises data
              // fetchSurprises();
          } catch (error) {
              setLoading(false);
              setdisplaysurprises(true);
              console.error('Error adding surprise:', error);
          } finally {
              setLoading(false);
              setdisplaysurprises(true);
          }
      
      
      
    }
    else{
        const formData = new FormData();
    formData.append('type', type);
    formData.append('title', title);
        formData.append('data', dataText);
        try {
            // Send POST request to upload data
            const response = await axios.post('https://nabonda.infinityfreeapp.com/server/add-surprise-text.php', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            setLoading(false);
            setdisplaysurprises(true);
          
            alert(response.data.message);
            // Optionally, fetch updated surprises data
            //fetchSurprises();
          } catch (error) {
            setLoading(false);
            setdisplaysurprises(true);

            console.error('Error adding surprise:', error);
          }finally{
            setLoading(false);
            setdisplaysurprises(true);

          }
          
    }

    
  };


  const handleAddShubodayam = async (event) => {
    event.preventDefault();
    // Prepare form data
    setLoading(true);
    
        const formData = new FormData();
        formData.append('data', shubodayam);
        try {
            // Send POST request to upload data
            const response = await axios.post('https://nabonda.infinityfreeapp.com/server/add-shubodayam-text.php', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            setLoading(false);
            setdisplaysurprises(true);
          
            alert(response.data.message);
            // Optionally, fetch updated surprises data
            //fetchSurprises();
          } catch (error) {
            setLoading(false);
            setdisplaysurprises(true);

            console.error('Error adding surprise:', error);
          }finally{
            setLoading(false);
            setdisplaysurprises(true);

          }
          
    

    
  };
  // Function to fetch surprises data

  const handleAddPaata = async (event) => {
    event.preventDefault();
    // Prepare form data
    setLoading(true);
    
        const formData = new FormData();
        formData.append('name', paata);
        formData.append('url',paataurl)
        try {
            // Send POST request to upload data
            const response = await axios.post('https://nabonda.infinityfreeapp.com/server/add-paata-text.php', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            setLoading(false);
            setdisplaysurprises(true);
          
            alert(response.data.message);
            // Optionally, fetch updated surprises data
            //fetchSurprises();
          } catch (error) {
            setLoading(false);
            setdisplaysurprises(true);

            console.error('Error adding surprise:', error);
          }finally{
            setLoading(false);
            setdisplaysurprises(true);

          }
          
    

    
  };

  const fetchSurprises = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://nabonda.infinityfreeapp.com/server/all-surprises.php');
      //console.log(response.data)
      setSurprises(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching surprises:', error);
      setLoading(false);
    }
    finally{
      setLoading(false);
    }
  };

  useEffect(()=>{
    fetchSurprises();
  },[]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        //setsurpriseDialog(false);
      }
    };

    document.addEventListener('keydown', handleEsc);

    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, []);


  

  // Function to handle file input change
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

 
  
  const handleClickAdd = ()=>{
    if(action==='add'){
      setAction('');
      setdisplaysurprises(true);
    }else{
      setAction('add');
    setdisplaysurprises(false);
    }
    
  }

  const handleClickAddShubodayam = ()=>{
    if(action==='addshubodayam'){
      setAction('');
      setdisplaysurprises(true);
    }else{
      setAction('addshubodayam');
    setdisplaysurprises(false);
    }
    
  }

  const handleClickAddPaata = ()=>{
    if(action==='addpaata'){
      setAction('');
      setdisplaysurprises(true);
    }else{
      setAction('addpaata');
    setdisplaysurprises(false);
    }
    
  }



  const confirmDelete = async() => {
    // Implement your delete logic here, e.g., call API to delete item
    //console.log(`Deleting item with ID ${deleteItemId}`);
    setLoading(true);
    const formData = new FormData();
    formData.append('id', deleteItemId);
      try {
        // Send POST request to upload data
        // http://localhost/nabonda_backend/
        //https://nabonda.infinityfreeapp.com/server/
        const response = await axios.post('https://nabonda.infinityfreeapp.com/server/del-surprise.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        setLoading(false);

        alert(response.data.message);
        // Optionally, fetch updated surprises data
        //fetchSurprises();
      } catch (error) {
        
        setLoading(false);

        console.error('Error deleting surprise:', error);
      }
      finally{
        setLoading(false);


      }

    // Close the delete dialog after deletion
    setShowDeleteDialog(false);
  };

  const cancelDelete = () => {
    // Close the delete dialog without performing delete action
    setShowDeleteDialog(false);
  };


  const handleDelSurprise = async(id) =>{
    // Prepare form data
    setDeleteItemId(id);
    setShowDeleteDialog(true);



    
      
    }
  

  // Render authentication form if not authenticated
  if (!authenticated) {
    return (
      <div className="admin">
        <h2>Admin Login</h2>
        <form onSubmit={handleAuthentication}>
          <input type="text" className="input" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
          <br></br>
          <input type="password" className="input" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
          <br></br>

          <button type="submit" className="submit-button">Login</button>
        </form>
        {error && <p className="error-message">{error}</p>}

      </div>
    );
  }

  // Render admin panel if authenticated
  return (
    <div className="admin">
       {loading && (
        <div className="loader-overlay">
          <Loader />
        </div>
      )}
      <div className="admin-actions">
        <button onClick={handleClickAdd} className='add-button'>Add</button>

        <button onClick={handleClickAddShubodayam} className='add-button'>Add Shubodayam</button>

        <button onClick={handleClickAddPaata} className='add-button'>Add Paata</button>

        <br></br>
        <br></br>
        {(displaysurprises) &&
        <div className="admin-list">     
          <table>
            <thead>

              <tr>
              <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ID&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
            <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Type&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
            <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Title&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
            <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Data&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
            <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Actions&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {surprises.map((surprise) => (
                <tr key={surprise.id}>
                  <td>{surprise.id}</td>
                  <td>{surprise.type}</td>
                  <td>{surprise.title}</td>
                  <td>
          
            <div className="dialog-content">
              {surprise.type === 'TEXT' && <center><p alt="Surprise" style={{ maxWidth: '250px', maxHeight: '180px', fontSize:'18px' }}>{surprise.data}</p></center>}
              {surprise.type === 'IMG' &&  <img src={`${surprise.data}`} alt="Surprise" style={{ maxWidth: '120px', maxHeight: '120px', objectFit: 'contain' }} />}
              {surprise.type === 'AUD' && <audio controls src={`${surprise.data}`} alt="Surprise" />}
              {surprise.type === 'VID' && <video controls src={`${surprise.data}`} alt="Surprise" style={{ maxWidth: '180px', maxHeight: '180px', objectFit: 'contain' }}/>}

           
          </div>
      
              </td>
                  <td>
{/*                   <button onClick={()=>handleEditSurprise()} className='edit-button'>Edit</button>
 */}                  <button onClick={()=>handleDelSurprise(surprise.id)} className='del-button'>Delete</button>

                  </td>

                </tr>
              ))}
            </tbody>
          </table>
         

{showDeleteDialog && (
        <div className="dialog-overlay">
          <div className="dialog-box">
            <div className="dialog-content">
              <h2>Confirm Delete</h2>
              <p>Are you sure you want to delete this item?</p>
              <div className="button-group">
                <button className="del-button" onClick={confirmDelete}>
                  Delete
                </button>
                <button className="edit-button" onClick={cancelDelete}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
        </div>
        }      
        
        
    </div>



      {/* Render Add form */}
      {action === 'add' && (
        <div className="admin-form">
          <h3>Add Surprise</h3>
          
          <form onSubmit={handleAddSubmit}>
            <select value={type} className='input'  onChange={(e) => setType(e.target.value)}>
              <option value="TEXT">Text</option>
              <option value="IMG">Image</option>
              <option value="AUD">Audio</option>
              <option value="VID">Video</option>
            </select>
            <br></br>
            <input type="text" placeholder="Title" className='input' value={title} onChange={(e) => setTitle(e.target.value)} />
            <br></br>
            {type !== 'TEXT' ? <input type="file" className='input' onChange={handleFileChange} /> : <input type="text" className='input' placeholder="Data" value={dataText} onChange={(e) => setdataText(e.target.value)} />}
            <br></br>
            <button type="submit" className='submit-button'>Submit</button>
          </form>
        </div>
      )}

      {/* Render Update/Delete functionality */}
      
      {action === 'addshubodayam' && (
        <div className="admin-form">
          <h3>Add Shubodayam</h3>
          
          <form onSubmit={handleAddShubodayam}>
           
            <input type="text" placeholder="Title" className='input' value={shubodayam} onChange={(e) => setShubodayam(e.target.value)} />
            
            <button type="submit" className='submit-button'>Submit</button>
          </form>
        </div>
      )}



{action === 'addpaata' && (
        <div className="admin-form">
          <h3>Add paata</h3>
          
          <form onSubmit={handleAddPaata}>
           
            <input type="text" placeholder="Name" className='input' value={paata} onChange={(e) => setpaata(e.target.value)} />
            <input type="text" placeholder="URL" className='input' value={paataurl} onChange={(e) => setpaataurl(e.target.value)} />
            <button type="submit" className='submit-button'>Submit</button>
          </form>
        </div>
      )}



      
    </div>
  );
};

export default Admin;
